import React from 'react'
import Layout from '../components/layout'
import { InlineWidget } from 'react-calendly'

export default function Consult () {
    return (
        <Layout>
            <div id='consult_page'>
                <div className='consult_component'>
                    <InlineWidget
                        styles={{
                            height: '80rem',
                            width: '100%'
                        }}
                        pageSettings={{
                            backgroundColor: '696969',
                            hideEventTypeDetails: false,
                            hideLandingPageDetails: false,
                            primaryColor: 'FFA500',
                            textColor: 'ffffff'
                        }}
                        url='https://calendly.com/chattanoogamarketsolutions'></InlineWidget>
                </div>
            </div>
        </Layout>
    )
}